export interface StoredIds {
  cookie?: string;
  indexedDb?: string;
  localStorage?: string;
}

export async function getStoredIds(): Promise<StoredIds> {
  const ids: StoredIds = {};

  // This is the Mozilla-recommended method for getting a cookie :-(
  //eslint-disable-next-line @microsoft/sdl/no-cookies
  ids.cookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)__mmapiwsid\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  if (window.localStorage) {
    ids.localStorage = localStorage.getItem('__mmapiwsid');
  }
  if (window.indexedDB) {
    ids.indexedDb = await getIndexedDbId();
  }
  return ids;
}

export async function setStoredIds(
  domain: string,
  value: string,
): Promise<void> {
  const key = '__mmapiwsid';
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2);

  if (!domain) {
    domain = document.domain;
  }
  try {
    //eslint-disable-next-line @microsoft/sdl/no-cookies
    document.cookie =
      key +
      '=' +
      value +
      '; Secure; expires=' +
      date.toUTCString() +
      '; domain=' +
      domain +
      '; path=/' +
      '; SameSite=None';
  } catch {
    // ignore cookie setting errors, which should not occur in real browsers
    // see: https://linear.app/maxmind/issue/UHUA-17649
  }
  if (window.localStorage) {
    // This throws an exception in private mode on Safari. See http://tinyurl.com/kcqewzw
    try {
      localStorage.setItem(key, value);
    } catch {}
  }

  await setStoredIdForDb(value);
}

const storeName = 'StoredId';

async function setStoredIdForDb(value: string): Promise<void> {
  const db = await getIndexedDb();

  if (!db) {
    return;
  }

  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);

  try {
    store.put({
      id: 0,
      value: value,
    });
  } catch {}
}

function openDb(): IDBOpenDBRequest {
  if (!window.indexedDB) {
    return null;
  }
  try {
    return indexedDB.open('__mmapiwsDb', 1);
  } catch {
    return null;
  }
}

async function getIndexedDb(): Promise<IDBDatabase | null> {
  const req = openDb();

  return new Promise((resolve) => {
    req.onsuccess = (e) => {
      resolve((e.currentTarget as IDBOpenDBRequest).result);
    };

    req.onerror = () => {
      resolve(null);
    };

    req.onupgradeneeded = (evt) => {
      (evt.currentTarget as IDBRequest).result.createObjectStore(storeName, {
        keyPath: 'id',
      });
    };
  });
}

async function getIndexedDbId(): Promise<string> {
  const db = await getIndexedDb();
  if (!db) {
    return null;
  }

  return new Promise((resolve) => {
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const req = store.get(0);
    req.onsuccess = function (evt: Event) {
      const record = (evt.target as IDBRequest).result;
      if (record) {
        resolve(record.value);
      }
      resolve(null);
    };

    req.onerror = () => resolve(null);
  });
}
